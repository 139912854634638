<template>
    <div>
        <el-dialog :visible.sync="editDialogVisible" :close-on-click-modal="false" :title="mode === 'edit' ? 'Edit entry' : 'Add entry'" width="65%">
            <el-alert type="info" :closable="false" :show-icon="true" v-if="mode === 'add'">
                Using this form one can add a new entry for the selected tournament. In most cases only the required fields might be edited. Especially the
                field "record key" should be left empty. In order to get ranking information for a player please select a player first and then current ranks
                will be displayed. These can be used to fill the filed entry rank.
            </el-alert>
            <FormGroup :model="selectedEntry" :schema="singlesEntriesSchema" :layout="layout" @change="onChange" />
            <div v-if="mode === 'add'" class="ranking-information" v-loading="loadingPlayerRank">
                <div v-if="!selectedRank">Ranking information is not yet loaded. Please select a player to display current ranks.</div>
                <div v-else>
                    Player has the following ranking information
                    <el-tag type="info">Singles Rank {{ selectedRank.rankSingles }}</el-tag>
                    <el-tag type="info">Doubles Rank {{ selectedRank.rankDoubles }}</el-tag>
                </div>
            </div>
            <div v-if="!isReadOnlyAccess" class="dialog-footer">
                <el-button type="primary" @click="saveChanges" :disabled="!selectedEntry || !selectedEntry.hasChanges()">Save changes</el-button>
                <el-button @click="editDialogVisible = false">Close</el-button>
                <span class="spacer"></span>
                <el-button v-if="mode === 'edit'" plain type="danger" @click="deleteEntry">Delete entry</el-button>
            </div>
        </el-dialog>

        <div class="flex-row mt-0">
            <span class="spacer"></span>
            <el-input
                size="mini"
                placeholder="Filter by player identifier"
                v-model="queryString"
                class="input-search mr-32"
                @keydown.esc.native="queryString = ''"
                :clearable="true"
            >
            </el-input>
            <el-input
                size="mini"
                placeholder="Entry Mode"
                v-model="queryStringEntryMode"
                class="input-search-small mr-16"
                @keydown.esc.native="queryStringEntryMode = ''"
                :clearable="true"
            >
            </el-input>
            <el-input
                size="mini"
                placeholder="Entry Status"
                v-model="queryStringEntryStatus"
                class="input-search-small mr-16"
                @keydown.esc.native="queryStringEntryStatus = ''"
                :clearable="true"
            >
            </el-input>
            <el-button v-if="!isReadOnlyAccess" @click="addEntry" plain type="primary" size="mini"> Add entry </el-button>
            <el-button @click="exportCsv" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <el-alert type="info" :closable="false" :show-icon="true" style="margin-top: 10px">
            Entry Types are defined as follows
            <br />
            <el-tag size="mini">Entered <b>EN</b></el-tag> <el-tag size="mini">NotEntered <b>NE</b></el-tag>
            <el-tag size="mini">AcceptedIntoMain <b>MD</b></el-tag>
            <el-tag size="mini">AcceptedIntoQualification <b>QL</b></el-tag>
            <el-tag size="mini">WithdrawnFromMainDrawAfterEntryDeadline <b>WM</b></el-tag>
            <el-tag size="mini">WithdrawnFromQualificationDrawAfterEntryDeadline <b>WQ</b></el-tag>
            <el-tag size="mini">WithdrawnBeforeEntryDeadline <b>WD</b></el-tag>
            <el-tag size="mini">WithdrawnAfterEntryDeadline <b>WE</b></el-tag> <el-tag>ObsoleteWithdraw <b>OW</b></el-tag>
            <el-tag size="mini">ObsoleteEnter <b>OE</b></el-tag> <el-tag size="mini">Other <b>OT</b></el-tag> <br />The old status is important for withdrawn
            players that were in main draw before. So if a player does not appear in main draw with a strike-through then most probably this is related to the
            old status not being correct. A player that has been accepted into main draw and then withdrew should have a WM as Entry Status and a MD in Old
            Status.
        </el-alert>

        <el-alert v-if="loadingError" :title="loadingError" type="error" class="mt-32" show-icon> </el-alert>

        <DataTable
            v-if="!loadingError"
            v-loading="loadingSinglesEntries || loading"
            :items="filteredSinglesEntries"
            :fields="[
                'playerFullName',
                'entryRank',
                'currentSinglesRank',
                'playerNationality',
                'entryPreference',
                'within',
                'entryMode',
                'entryStatus',
                'orgStatus',
                'entryDate',
            ]"
            :schema="singlesEntriesSchema"
            @item-clicked="itemClicked"
            :autoSort="true"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SinglesEntry } from '../models/singles-entry';
import { csvExporter } from '../utils/csv-exporter';
import moment from 'moment';
import FileSaver from 'file-saver';
import axios from 'axios';
import { isReadOnlyAccess } from '../services/authGroups';

export default {
    props: ['tournamentId', 'tournamentYear'],
    data() {
        return {
            singlesEntriesSchema: SinglesEntry.schema,
            layout: SinglesEntry.layout,
            editDialogVisible: false,
            selectedEntry: null,
            selectedRank: null,
            mode: null,
            queryString: '',
            queryStringEntryMode: '',
            queryStringEntryStatus: '',
            loading: false,
            loadingPlayerRank: false,
            isReadOnlyAccess: isReadOnlyAccess(),            
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('singlesEntries', ['singlesEntries', 'loadingSinglesEntries', 'loadingError']),
        filteredSinglesEntries() {
            if (!this.singlesEntries) {
                return [];
            }

            return this.singlesEntries
                .filter((r) => !this.queryString || !r.playerFullName || r.playerFullName.toLowerCase().includes(this.queryString.toLowerCase()))
                .filter((r) => !this.queryStringEntryMode || !r.entryMode || r.entryMode.toLowerCase().includes(this.queryStringEntryMode.toLowerCase()))
                .filter(
                    (r) => !this.queryStringEntryStatus || !r.entryStatus || r.entryStatus.toLowerCase().includes(this.queryStringEntryStatus.toLowerCase()),
                );
        },
    },
    methods: {
        exportCsv() {
            const data = csvExporter(this.singlesEntriesSchema, this.singlesEntries);
            const file = new File([data], `singles_entries_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
            FileSaver.saveAs(file);
        },
        ...mapActions('singlesEntries', ['findSinglesEntries', 'createSinglesEntry', 'updateSinglesEntry', 'deleteSinglesEntry']),
        refresh() {
            this.findSinglesEntries({
                id: this.tournamentId,
                year: this.tournamentYear,
            });
        },
        itemClicked(item) {
            this.selectedEntry = new SinglesEntry(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        addEntry() {
            let that = this;
            that.selectedEntry = new SinglesEntry({
                season: that.tournamentYear,
                tournamentId: that.tournamentId,
            });
            that.mode = 'add';
            that.editDialogVisible = true;
        },
        saveChanges() {
            if (this.mode === 'add') {
                this.createSinglesEntry(this.selectedEntry).then(() => {
                    this.refresh();
                    this.editDialogVisible = false;
                });
            } else {
                this.updateSinglesEntry(this.selectedEntry).then(() => {
                    this.refresh();
                    this.editDialogVisible = false;
                });
            }
        },
        onChange() {
            if (this.selectedRank === null && this.selectedEntry.data.playerId !== undefined && this.selectedEntry.data.playerId !== '') {
                this.loadingPlayerRank = true;
                axios
                    .get(`${process.env.VUE_APP_API_URL}/Ranking/Current/Singles/`,{
                        params: {
                            QueryString: this.selectedEntry.data.playerId
                        }
                    })
                    .then((data) => {
                        this.selectedRank = data.data;
                        this.loadingPlayerRank = false;
                    });
            }
        },
        deleteEntry() {
            this.$confirm('You are about to delete a current Tournament entry. Please confirm.', 'Delete tournament entry', {
                confirmButtonText: 'Delete tournament entry',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    this.$prompt(
                        'Warning! This will irrevocably delete the selected tournament entry. To continue, please enter the Tournament ID.',
                        'Delete tournament entry',
                        {
                            confirmButtonText: 'Delete tournament entry',
                            cancelButtonText: 'Cancel',
                            type: 'warning',
                        },
                    )
                        .then((entry) => {
                            if (entry.action === 'confirm' && entry.value === '' + this.selectedEntry.data.tournamentId) {
                                this.deleteSinglesEntry(this.selectedEntry)
                                    .then(() => {
                                        this.refresh();
                                        this.editDialogVisible = false;
                                        this.$notify({
                                            title: 'Delete tournament entry',
                                            message: 'Tournament entry deleted',
                                            type: 'success',
                                            duration: 5000,
                                        });
                                    })
                                    .catch(() => {
                                        this.$notify({
                                            title: 'Delete tournament entry',
                                            message: 'Tournament entry could not be deleted',
                                            type: 'error',
                                            duration: 5000,
                                        });
                                    });
                            } else {
                                this.$notify({
                                    title: 'Delete tournament entry',
                                    message: 'Tournament entry not deleted',
                                    type: 'info',
                                    duration: 5000,
                                });
                            }
                        })
                        .catch(() => {});
                })
                .catch(() => {});
        },
    },
};
</script>

<style>
.input-search {
    max-width: 384px;
}

.input-search-small {
    max-width: 150px;
}
.ranking-information {
    min-height: 50px;
}

.el-tag {
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}
</style>
